var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "28",
        height: "28",
        viewBox: "0 0 28 28",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("icon-clicked")
        },
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2 14C2 20.6274 7.37258 26 14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14Z",
          fill: "#67C23A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18 11L12.5 16.5L10 14",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }